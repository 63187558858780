import React, { FunctionComponent } from 'react';

interface Props {
  title: string
}

const HeadContainer: FunctionComponent<Props> = ({
  title,
}) => {
  return (
    <>
    <title>{title} | CALLABTECH</title>
    <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    </>
  )   
};

export default HeadContainer;
