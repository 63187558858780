import React, { FunctionComponent } from 'react';
import PageWrapper from '../lib/PageWrapper';
import HeadContainer from '../lib/HeadContainer';

const Main: FunctionComponent = () => {
  return (
    <PageWrapper>
      <div>
        Main
      </div>
    </PageWrapper>
  );
};

export default Main;

export function Head() {
  return (
    <HeadContainer
      title="Main"
    />
  )
}
